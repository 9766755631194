import React, { Component, useState } from "react";
import { Layout, Menu, Button, Dropdown, Card, Avatar, Spin, Modal, Typography } from 'antd';
import { Link, Switch, Route, BrowserRouter } from 'react-router-dom'
import { MenuFoldOutlined, LogoutOutlined } from '@ant-design/icons'
import routes from '../../routes'
import './dashboard.css'
import { Buffer } from 'buffer';
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import { checkSessionFetcher, logout } from "../../service/services";

const { Header, Sider, Content } = Layout;

const { Meta } = Card;

const Dashboard = () => {
    let history = useHistory();

    if (!localStorage.getItem('token')) {
        history.push('/signin');
    }

    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [showWarningModal, setShowWarningModal] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [selected, setSelected] = useState('1');
    const [visibleEditProfileForm, setVisibleEditProfileForm] = useState(false);
    const [role, setRole] = useState(profile ? Buffer.from(profile.role.substring(1, profile.role.length - 2), 'base64').toString('utf8') : "");

    const logoutSession = async () => {

        setLoading(true);

        await logout();

        // const token = localStorage.getItem('token');

        // await Instance.get('/auth/logout/' + token);

        // localStorage.removeItem('token');
        // localStorage.removeItem('profile');

        setLoading(false);
        setTimeout(() => {
            history.push('/signin');
        }, 1500);
    }

    const checkSessionResponse = useSWR([localStorage.getItem('token')], checkSessionFetcher, { revalidateOnFocus: true });
    if (checkSessionResponse.data) {
        if (checkSessionResponse.data.message === 'offline') {
            return (
                <Modal
                    title={<Typography.Title level={5}>แจ้งเตือน</Typography.Title>}
                    style={{ top: 25 }}
                    visible={true}
                    okText={'ฉันเข้าใจแล้ว'}
                    onOk={() => logoutSession()}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <p>คุณเข้าสู่ระบบเกินกำหนด ระบบจะทำการดีดอออก</p>
                </Modal>
            )
        }
    }

    const renderMenu = () => {
        return (
            <Menu>
                <Menu.Item key={1}>
                    <Card
                        style={{ width: 300 }}
                        actions={[
                            <LogoutOutlined type="logout" key="logout" onClick={() => logoutSession()} />,
                        ]}
                    >
                        <Meta
                            avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                            title={profile != null ? profile.nickname : ""}
                            description={role ? role === 'admin' ? "ผู้ดูแล" : role === 'member' ? "สมาชิก" : role === 'moderator' ? "ผู้ดูแลรอง" : "หัวหน้า" : "ไม่มี"}
                        />
                    </Card>
                </Menu.Item>
            </Menu>
        )
    }

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <BrowserRouter>
            <Spin spinning={loading}>
                <div>
                    <Layout className="layout" style={{ height: '100%' }}>
                        <Sider trigger={null} collapsible collapsed={collapsed}>
                            <div className="sider">Check Slip</div>
                            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                                {
                                    routes.map((route, idx) => {
                                        const index = route.role.findIndex(v => v === role);
                                        if (index !== -1) {
                                            return <Menu.Item key={idx + 1}>
                                                <Link to={route.path} className="nav-text">
                                                    {route.icon}
                                                    <span>{route.nameTH}</span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                        return null;
                                    })
                                }
                            </Menu>
                        </Sider>
                        <Layout>
                            <Header style={{ padding: 0, backgroundColor: "white" }}>
                                <MenuFoldOutlined
                                    className="trigger"
                                    style={{ paddingLeft: 22, fontSize: 20, color: 'grey' }}
                                    onClick={() => toggle()}
                                />

                                <Dropdown overlay={renderMenu()} placement="bottomLeft">
                                    <Button type="defualt" style={{ float: 'right', marginRight: 10, marginTop: 15 }}>โปรไฟล์</Button>
                                </Dropdown>

                            </Header>
                            <Content className="content content-default-color" id="content">
                                <Switch>
                                    {
                                        routes.map((route, idx) => {
                                            const index = route.role.findIndex(v => v === role);
                                            if (index !== -1) {
                                                return route.component
                                                    ? (<Route key={idx} path={route.path} exact={route.exact}
                                                        name={route.name} render={props => (<route.component {...props} />)} />)
                                                    : (null);
                                            }
                                            return null;
                                        })
                                    }
                                </Switch>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </Spin>
        </BrowserRouter>
    )
}

export default Dashboard;

// export default class Dashboard extends Component {

//     constructor(props) {
//         super(props)

//         if (!localStorage.getItem('token')) {
//             props.history.push('/signin');
//         }

//         const profile = JSON.parse(localStorage.getItem('profile'));
//         const role = profile ? Buffer.from(profile.role.substring(1, profile.role.length - 2), 'base64').toString('utf8') : "";

//         this.state = {
//             collapsed: false,
//             loading: false,
//             loadingProfile: false,
//             profile: profile,
//             selected: '1',
//             visibleEditProfileForm: false,
//             role
//         };
//     }

//     renderMenu = () => {

//         return (
//             <Menu>
//                 <Menu.Item key={1}>
//                     <Card
//                         style={{ width: 300 }}
//                         actions={[
//                             <LogoutOutlined type="logout" key="logout" onClick={this.logout} />,
//                         ]}
//                     >
//                         <Meta
//                             avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
//                             title={this.state.profile != null ? this.state.profile.nickname : ""}
//                             description={this.state.role ? this.state.role === 'admin' ? "ผู้ดูแล" : "สมาชิก" : ""}
//                         />
//                     </Card>
//                 </Menu.Item>
//             </Menu>
//         )
//     }

//     toggle = () => {
//         this.setState({
//             collapsed: !this.state.collapsed,
//         });
//     };

//     logout = async () => {

//         this.setState({
//             loading: true
//         })

//         const token = localStorage.getItem('token');

//         await Instance.get('/auth/logout/' + token);

//         localStorage.removeItem('token');
//         localStorage.removeItem('profile');

//         this.setState({
//             loading: false
//         })

//         this.props.history.push('/signin');

//     }

//     render() {
//         return (
//             <BrowserRouter>
//                 <Spin spinning={this.state.loading}>
//                     <div>
//                         <Layout className="layout">
//                             <Sider trigger={null} collapsible collapsed={this.state.collapsed} style={{ height: '100vh' }}>
//                                 <div className="sider">Check Slip</div>
//                                 <Menu theme="dark" mode="inline" defaultSelectedKeys={[this.state.selected]}>
//                                     {
//                                         routes.map((route, idx) => {
//                                             const index = route.role.findIndex(v => v === this.state.role);
//                                             if (index !== -1) {
//                                                 return <Menu.Item key={idx + 1}>
//                                                     <Link to={route.path} className="nav-text">
//                                                         {route.icon}
//                                                         <span>{route.nameTH}</span>
//                                                     </Link>
//                                                 </Menu.Item>
//                                             }
//                                             return null;
//                                         })
//                                     }
//                                 </Menu>
//                             </Sider>
//                             <Layout>
//                                 <Header style={{ padding: 0, backgroundColor: "white" }}>
//                                     <MenuFoldOutlined
//                                         className="trigger"
//                                         style={{ paddingLeft: 22, fontSize: 20, color: 'grey' }}
//                                         onClick={this.toggle}
//                                     />

//                                     <Dropdown overlay={this.renderMenu} placement="bottomLeft">
//                                         <Button type="defualt" style={{ float: 'right', marginRight: 10, marginTop: 15 }}>โปรไฟล์</Button>
//                                     </Dropdown>

//                                 </Header>
//                                 <Content className="content">
//                                     <Switch>
//                                         {
//                                             routes.map((route, idx) => {
//                                                 const index = route.role.findIndex(v => v === this.state.role);
//                                                 if (index !== -1) {
//                                                     return route.component
//                                                         ? (<Route key={idx} path={route.path} exact={route.exact}
//                                                             name={route.name} render={props => (<route.component {...props} />)} />)
//                                                         : (null);
//                                                 }
//                                                 return null;
//                                             })
//                                         }
//                                     </Switch>
//                                 </Content>
//                             </Layout>
//                         </Layout>
//                     </div>
//                 </Spin>
//             </BrowserRouter>
//         );
//     }
// }