import React, { Component, useCallback, useEffect, useState } from 'react';
import { InboxOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row, Select, Spin, Upload, Typography, Result, Modal, List } from "antd";
import axios from "axios";
import Notification from "../../components/Notification";
import './checkSlip.css'
import { useHistory } from 'react-router-dom';
import { checkSlip, getServerOnProfile, getServers, saveImage, saveMemberLog } from '../../service/services';
import { disconnectSocket } from '../../service/socketio.service';
import moment from 'moment';
import { getDateTimeDuration, guessYear } from '../../utils/utils';

const { Dragger } = Upload;
const { Option } = Select;
const { Title } = Typography;

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => {
            reject('Error: ', error);
        };
    })
}

const CheckSlip = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [serverList, setServerList] = useState([]);
    const [server, setServer] = useState(null);
    const [serverSeleted, setServerSeleted] = useState(null);
    const [sms, setSms] = useState(null);
    const [status, setStatus] = useState('');
    const [imageSelected, setImageSelected] = useState(null);
    const [showOutput, setShowOutput] = useState(false);
    const [showSmsModal, setShowSmsModal] = useState(false);
    const [profile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [smsExtract, setSmsExtract] = useState(null);

    const onCopyImage = useCallback((evt) => {
        setLoading(true);
        setShowOutput(false);
        const clipboardItems = evt.clipboardData.items;
        const items = [].slice.call(clipboardItems).filter((item) => {
            // Filter the image items only
            return item.type.indexOf('image') !== -1;
        });

        if (items.length === 0) {
            Notification('กรุณาคัดลอกรูปภาพเท่านั้น', 'error');
            setLoading(false);
            return;
        }

        const item = items[0];
        const blob = item.getAsFile();
        const temp = [blob];
        setFileList(temp);

        const imageEle = document.getElementById('preview');
        imageEle.src = URL.createObjectURL(blob);
        setImageSelected(URL.createObjectURL(blob));

        let element = document.getElementById("content");
        clearColorClass(element);
        element.classList.add("content-default-color");
        
        setLoading(false);
    });

    const onEnterCheck = useCallback((evt) => {
        if (evt.code === 'Enter' || evt.code === 'NumpadEnter') onCheck();
        else return;
    });

    useEffect(() => {
        disconnectSocket();
        document.addEventListener('paste', onCopyImage);
        document.addEventListener('keydown', onEnterCheck);

        if (serverList.length === 0) {
            try {
                if (!JSON.parse(localStorage.getItem('profile')).servers) {
                    getServers().then(res => {
                        if (res.status === 200) {
                            const servers = res.data.data;
                            const serverFilter = servers.filter(v => {
                                if (v.name.indexOf('(') !== -1) {
                                    return v;
                                }
                            })
                            // console.log(servers);
                            setServerList(serverFilter);
                        } else setServerList([]);
                    }).catch(reason => setServerList([]));
                } else {
                    const servers = getServerOnProfile();
                    setServerList(servers);
                }
            } catch (error) {
                history.push('/signin');
            }

        }

        return () => {
            document.removeEventListener('paste', onCopyImage);
            document.removeEventListener('keydown', onEnterCheck)
        }
    }, [onCopyImage, onEnterCheck])

    const onServerChange = (e) => {
        let element = document.getElementById("content");
        clearColorClass(element);
        element.classList.add("content-default-color");

        const myServerIndex = serverList.findIndex(server => server.id === e);
        setServerSeleted(e);
        setServer(serverList[myServerIndex].ip);
    }

    // window.addEventListener('keypress', e => {
    //     console.log(e.key)
    //     if (e.key === 'Enter') {
    //         onCheck();
    //     }
    // });

    const onReset = () => {
        let element = document.getElementById("content");
        clearColorClass(element);
        element.classList.add("content-default-color");

        setFileList([]);
        setStatus("");
        setSms(null);
        setImageSelected(null);
        setShowOutput(false);
    }

    const onCheck = async (e) => {
        setShowOutput(false);
        setLoading(true);
        if (!server) {
            Notification('กรุณาเลือกเซิร์ฟเวอร์', 'error');
            setLoading(false);
            return;
        }

        if (fileList.length === 0) {
            Notification('กรุณาเลือกรูปภาพ', 'error');
            setLoading(false);
            return;
        }

        const base64 = await getBase64(fileList[0]);
        // const response = await axios.post(server + ":5000" + "/sms/check-slip", { image: base64 }, { headers: { 'Content-Type': 'application/json' } });

        try {
            const serverId = serverList.findIndex(v => v.id === serverSeleted);
            const response = await checkSlip(server, base64, serverList[serverId].bank, serverList[serverId].accountNameEN, serverList[serverId].accountNameTH, profile.nickname); // axios.post(server + ":5000" + "/sms/check-slip", { image: base64 }, { timeout: 1000 * 30, headers: { 'Content-Type': 'application/json' } });
            setSms(response.data.sms);
            setStatus(response.data.status);
            setSmsExtract(response.data.extract);
            setShowOutput(true);
            setLoading(false);
            
            if (response.data.status === 'undetection') {
                // const data = {
                //     img: base64,
                //     server: serverList[serverId].id,
                //     member: JSON.parse(localStorage.getItem('profile')).id
                // }
                // Instance.post('image', data).then(v => {
                //     return;
                // }).catch(reason => {
                //     return;
                // });
                saveImage(base64, serverList[serverId].id, JSON.parse(localStorage.getItem('profile')).id, response.data.text)
            }

            saveMemberLog(
                JSON.parse(localStorage.getItem('profile')).id,
                response.data.text,
                base64,
                response.data.extract.date,
                response.data.extract.time,
                response.data.extract.amount,
                response.data.bankName,
                response.data.status,
                serverList[serverId].id,
            )

            // const log = {
            //     member: JSON.parse(localStorage.getItem('profile')).id,
            //     text: response.data.text,
            //     image: base64,
            //     date: response.data.extract.date,
            //     time: response.data.extract.time,
            //     amount: response.data.extract.amount,
            //     bank: response.data.bankName,
            //     detectionType: response.data.status,
            //     server: serverList[serverId].id,
            // };
            // Instance.post('member-logging', log).then(v => {
            //     return;
            // }).catch(reason => {
            //     return;
            // });

        } catch (error) {
            Notification("เกิดข้อพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง", "error");
            setSms(null);
            setStatus('');
            setShowOutput(false);
            setLoading(false);
        }
        // setSms(response.data.sms);
        // setStatus(response.data.status);
        // setShowOutput(true);
        // setLoading(false);
    }

    const clearColorClass = (element) => {
        element.classList.remove(["content-default-color"]);
        element.classList.remove(["content-fake-color"]);
        element.classList.remove(["content-time-color"]);
    }

    const showResult = () => {
        let element = document.getElementById("content");
        clearColorClass(element);

        const serverIndex = serverList.findIndex(v => v.id === serverSeleted);
        const serverName = serverList[serverIndex].name;

        if (status === 'found and sure') {
            const currentDate = moment(new Date());
            const splitSlipDateTime = smsExtract.date.split("/");

            const slipDateTime = smsExtract.sms_address === "KBank" ?
            moment(guessYear(splitSlipDateTime[2]) + "-" + splitSlipDateTime[1] + "-" + splitSlipDateTime[0] + " " + smsExtract.time, "YYYY-MM-DD HH:mm") : 
            moment(moment(splitSlipDateTime[2], "YY").format("YYYY") + "-" + splitSlipDateTime[1] + "-" + splitSlipDateTime[0] + " " + smsExtract.time, "YYYY-MM-DD HH:mm");

            const duration = getDateTimeDuration(currentDate, slipDateTime);

            element.classList.add(["content-default-color"]);
            if (duration.minute > 10) {
                element.classList.remove(["content-default-color"]);
                element.classList.add(["content-time-color"]);
            }

            const showData = <p style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>เครื่อง: {serverName}<br />SMS ID: {sms[0]._id} จากบัญชี: {sms[0].account_from} <br /> วันที่: {sms[0].date} เวลา: {sms[0].time} <br /> ระยะเวลาเช็คสลิปเทียบกับเวลาปัจจุบัน: <span style={{ color: 'red' }}>{duration.text}</span> <br /> จำนวนเงิน: <span style={{ color: 'green' }}>{sms[0].amount}</span> <br /> เช็ค: {sms[0].check ? <span style={{ color: 'green' }}>เช็คแล้ว</span> : <span style={{ color: 'red' }}>ยังไม่เช็ค</span>}</p>;
            return (
                <Result
                    style={{ padding: 5 }}
                    status="success"
                    title={<p style={{ color: 'green', marginBottom: 0 }}>สลิปจริง {duration.hours > 0 ? <span style={{ color: 'red' }}>เวลาเช็คปัจจุบันห่างจากเวลาในสลิปเกิน 1 ชม</span> : ""}</p>}
                    subTitle={showData}
                />
            )
        }

        if (status === 'found but many') {

            const currentDate = moment(new Date());
            const splitSlipDateTime = smsExtract.date.split("/");
            const slipDateTime = smsExtract.sms_address === "KBank" ?
            moment(guessYear(splitSlipDateTime[2]) + "-" + splitSlipDateTime[1] + "-" + splitSlipDateTime[0] + " " + smsExtract.time, "YYYY-MM-DD HH:mm") : 
            moment(moment(splitSlipDateTime[2], "YY").format("YYYY") + "-" + splitSlipDateTime[1] + "-" + splitSlipDateTime[0] + " " + smsExtract.time, "YYYY-MM-DD HH:mm");

            const duration = getDateTimeDuration(currentDate, slipDateTime);

            if (duration.minute > 10) {
                element.classList.add(["content-time-color"]);
            }

            return (
                <Result
                    style={{ padding: 5 }}
                    status="success"
                    title={<p style={{ color: 'green', marginBottom: 0 }}>สลิปจริง<u>มีมากกว่า 1</u> {duration.hours > 0 ? <span style={{ color: 'red' }}>เวลาเช็คปัจจุบันห่างจากเวลาในสลิปเกิน 1 ชม</span> : ""}</p>}
                    subTitle={
                        <>
                            <p style={{ fontSize: 18, color: 'black', fontWeight: 'bold' }}>ระยะเวลาเช็คสลิปเทียบกับเวลาปัจจุบัน: <span style={{ color: 'red' }}>{duration.text}</span></p>
                            <Button type="default" onClick={() => setShowSmsModal(true)}>ดูข้อมูล</Button>
                        </>
                    }
                />
            )
        }

        if (status === 'not sure') {
            const currentDate = moment(new Date());
            const splitSlipDateTime = smsExtract.date.split("/");
            const slipDateTime = smsExtract.sms_address === "KBank" ?
            moment(guessYear(splitSlipDateTime[2]) + "-" + splitSlipDateTime[1] + "-" + splitSlipDateTime[0] + " " + smsExtract.time, "YYYY-MM-DD HH:mm") : 
            moment(moment(splitSlipDateTime[2], "YY").format("YYYY") + "-" + splitSlipDateTime[1] + "-" + splitSlipDateTime[0] + " " + smsExtract.time, "YYYY-MM-DD HH:mm");

            const duration = getDateTimeDuration(currentDate, slipDateTime);

            element.classList.add(["content-default-color"]);
            if (duration.minute > 10) {
                element.classList.remove(["content-default-color"]);
                element.classList.add(["content-time-color"]);
            }

            return (
                <Result
                    style={{ padding: 5 }}
                    status="warning"
                    title={<p style={{ color: '', marginBottom: 0 }}><Typography.Text type="warning">ไม่แน่ใจ {duration.hours > 0 ? <span style={{ color: 'red' }}>เวลาเช็คปัจจุบันห่างจากเวลาในสลิปเกิน 1 ชม</span> : ""}</Typography.Text></p>}
                    subTitle={
                        <>
                            <span style={{ fontSize: 18, color: 'black', fontWeight: 'bold' }}>ไม่มีสลิปในเวลา แต่มีสลิปใกล้เคียง</span>
                            <p style={{ fontSize: 18, color: 'black', fontWeight: 'bold' }}>ระยะเวลาเช็คสลิปเทียบกับเวลาปัจจุบัน: <span style={{ color: 'red' }}>{duration.text}</span></p>
                            <Typography.Link href="#" onClick={() => setShowSmsModal(true)}>
                                ดูข้อมูลสลิปที่ใกล้เคียง
                            </Typography.Link>
                        </>
                    }
                />
            )
        }

        if (status === 'fake') {
            element.classList.add(["content-fake-color"]);
            return (
                <Result
                    style={{ padding: 5 }}
                    status="error"
                    title={<p style={{ color: 'white', marginBottom: 0, fontSize: 24 }}>ไม่พบสลิป</p>}
                    subTitle={<p style={{ color: 'white', marginBottom: 0, fontSize: 18 }}>ไม่พบสลิปที่ค้นหา สลิปไม่มีข้อมูลในเซิร์ฟเวอร์ หรืออาจเป็นสลิปปลอม</p>}
                />
            )
        }

        if (status === 'undetection') {
            element.classList.add(["content-default-color"]);
            return (
                <Result
                    style={{ padding: 5 }}
                    status="error"
                    title={<p style={{ color: 'red', marginBottom: 0 }}>ไม่สามารถตรวจสอบได้</p>}
                    subTitle="ไม่พบข้อมูล"
                />
            )
        }
    }

    return (
        <>
            <Spin spinning={loading}>
                <Modal
                    title={<Title level={5}>ข้อมูล SMS ในเวลาเดียวกัน</Title>}
                    style={{ top: 25 }}
                    visible={showSmsModal}
                    onOk={() => setShowSmsModal(false)}
                    onCancel={() => setShowSmsModal(false)}
                    width={1000}
                >
                    {sms ? <>
                        <List
                            itemLayout="horizontal"
                            dataSource={sms}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={'SMS_ID: ' + item._id}
                                        description={<p>เครื่อง: {server}<br />จากบัญชี: {<span style={{ color: 'green' }}><b>{item.account_from}</b></span>} <br /> วันที่: {item.date} เวลา: {item.time} <br /> จำนวนเงิน: {item.amount} <br /> เช็ค: {item.check ? <span style={{ color: 'green' }}><b>เช็คแล้ว</b></span> : <span style={{ color: 'red' }}><b>ยังไม่เช็ค</b></span>}</p>}
                                    />
                                </List.Item>
                            )}
                        />
                    </> : <></>}
                </Modal>
                {serverList ?
                    <Select style={{ width: '100%', marginBottom: 10 }} placeholder="เลือกเครื่อง" onChange={(e) => onServerChange(e)}>
                        {serverList.map(v => <Option value={v.id}>{v.name}</Option>)}
                    </Select> : <></>}
                <Dragger
                    style={{ marginBottom: 10 }}
                    accept=".png,.jpg,jpeg"
                    fileList={fileList}
                    multiple={false}
                    showUploadList={false}
                    onChange={(e) => {
                        const file = e.file;
                        const imageEle = document.getElementById('preview');
                        imageEle.src = URL.createObjectURL(file);
                        setImageSelected(URL.createObjectURL(file));
                    }}
                    beforeUpload={(e) => {
                        const temp = [e];
                        setFileList(temp);
                        return false;
                    }}
                    onDrop={(e) => {
                        // console.log('Dropped files', e.dataTransfer.files);
                    }}

                >
                    {fileList.length === 0 ?
                        <>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">คลิกที่นี่ หรือคัดลอกเพื่ออัพโหลดรูปภาพ</p>
                        </> : <><img className="preview" id="preview" /></>}

                </Dragger>
                <div style={{ textAlign: 'center', padding: 10, margin: 10 }}>
                    <Button type="primary" onClick={() => onCheck()}>เช็คสลิป</Button> <Button type="default" danger onClick={() => onReset()}>reset</Button>
                </div>

                {showOutput ?
                    <div style={{ textAlign: 'center', padding: 5, margin: 5 }}>
                        <Title level={4} style={{ textAlign: 'left' }}>ผลลัพธ์</Title>
                        <Row>
                            <Col md={8} sm={8}>
                                <Image
                                    width={200}
                                    height={250}
                                    src={imageSelected}
                                />
                            </Col>
                            <Col md={16} sm={8}>
                                {showResult()}
                            </Col>
                        </Row>
                    </div> : <></>}
            </Spin>
        </>
    );

}

export default CheckSlip;