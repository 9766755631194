import { IdcardOutlined } from "@ant-design/icons";
import { Button, DatePicker, Descriptions, Empty, Image, Select, Space, Spin, Table, Tabs, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Notification from "../../components/Notification";
import { getMembers, getSearchMemberLogs, getServerOnProfile, getServers } from "../../service/services";
import { disconnectSocket } from "../../service/socketio.service";
import { numberWithCommas } from "../../utils/utils";
import './report.css'


const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const reportTypes = [
    {
        value: 'now',
        text: 'วันนี้'
    },
    {
        value: 'yesterday',
        text: 'เมื่อวานนี้'
    },
    {
        value: 'weekly',
        text: 'อาทิตย์นี้'
    },
    {
        value: 'monthly',
        text: 'เดือนนี้'
    },
    {
        value: 'between',
        text: 'เลือกวันที่'
    },
];

const logColumns = (previewImage) => {
    const columns = [
        {
            title: 'NO',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            align: 'center',
            width: 50,
            render: (text, record, index) => {
                return <>{index + 1}</>
            }
        },
        {
            title: 'รูปภาพ',
            dataIndex: 'image',
            key: 'image',
            align: 'center',
            width: 100,
            render: (text, record, index) => {
                return <>
                    <Button size="small" type="link" onClick={() => previewImage(record)}>รูปภาพ</Button>
                </>
            }
        },
        {
            title: 'วันที่',
            dataIndex: 'date',
            key: 'date',
            width: 100,
        },
        {
            title: 'เวลา',
            dataIndex: 'time',
            key: 'time',
            width: 100,
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            key: 'amount',
            width: 100,
        },
        {
            title: 'ธนาคาร',
            dataIndex: 'bank',
            key: 'bank',
            width: 100,
        },
        {
            title: 'ตรวจจับ',
            dataIndex: 'detectionType',
            key: 'detectionType',
            width: 100,
            render: (text, record, index) => {
                if (text === 'found and sure') text = <Text type="success">สลิปจริง</Text>
                else if (text === 'found but many') text = <Text type="secondary">สลิปจริงมากกว่า 1</Text>
                else if (text === 'not sure') text = <Text type="warning">ไม่แน่ใจ</Text>
                else if (text === 'fake') text = <Text type="danger">อาจเป็นสลิปปลอม</Text>
                else if (text === 'undetection') text = <Text type="danger">ตรวจจับไม่ได้</Text>

                return text;
            }
        },
        {
            title: 'บัญชี',
            dataIndex: 'server',
            key: 'server',
            width: 100,
            render: (text, record, index) => {
                return record.server.name;
            }
        },
        {
            title: 'ข้อความ',
            dataIndex: 'text',
            key: 'text',
            fixed: 'left',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) => {
                return <>
                    <Tooltip placement="top" title={text}>
                        {text}
                    </Tooltip>
                </>
            }
        },

    ];

    return columns;
}

const MinorReport = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [memberSelected, setMemberSelected] = useState(null);
    const [reportTypeSelected, setReportTypeSelected] = useState(null);
    const [servers, setServers] = useState([]);
    const [serverSeleted, setServerSelected] = useState([]);
    const [logs, setLogs] = useState(null);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD 00:00:00"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD 23:59:59"));
    const [showImage, setShowImage] = useState(false);
    const [imageSelected, setImageSelected] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [member, setMember] = useState(null);

    // const member = useSWR([''], memberFetcher, { revalidateOnFocus: false });

    useEffect(() => {
        disconnectSocket();
        const response = getMembers();
        response.then(res => {
            if (res.status !== 200) {
                setMember(null);
            } else {
                setMember(res.data.data);
            }
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        if (servers.length === 0) {
            try {
                if (!JSON.parse(localStorage.getItem('profile')).servers) {
                    getServers().then(res => {
                        if (res.status === 200) setServers(res.data.data);
                        else setServers([]);
                    }).catch(reason => setServers([]));
                } else {
                    const servers = getServerOnProfile();
                    setServers(servers);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                history.push('/signin');
            }
        }
    }, [])

    const onChangeDate = (e) => {
        if (e) {
            const startDate = moment(e[0]).format("YYYY-MM-DD 00:00:00");
            const endDate = moment(e[1]).format("YYYY-MM-DD 23:59:59");
            setStartDate(startDate);
            setEndDate(endDate);
        }
    }

    const onReportTypeChange = (e) => {
        let startNewDate = '';
        let endNewDate = '';
        if (e === 'now') {
            startNewDate = moment().format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().format("YYYY-MM-DD 23:59:59");
        } else if (e === 'yesterday') {
            startNewDate = moment().subtract(1, 'day').format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().subtract(1, 'day').format("YYYY-MM-DD 23:59:59");
        } else if (e === 'weekly') {
            startNewDate = moment().subtract(6, 'day').format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().format("YYYY-MM-DD 23:59:59");
        } else if (e === 'monthly') {
            startNewDate = moment().subtract(30, 'day').format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().format("YYYY-MM-DD 23:59:59");
        }
        setShowResult(false);
        setStartDate(startNewDate);
        setEndDate(endNewDate);
        setReportTypeSelected(e);
    }

    const onMemberChange = (e) => {
        setShowResult(false);
        setMemberSelected(e);
    }

    const onSearch = async () => {

        if (!memberSelected) {
            Notification('กรุณาเลือกสมาชิก', 'error');
            return;
        }

        if (!reportTypeSelected) {
            Notification('กรุณาเลือกประเภทรายงาน', 'error');
            return;
        }

        if (!startDate || !endDate) {
            Notification('กรุณาเลือกวันที่', 'error');
            return;
        }

        setLoading(true);
        const response = await getSearchMemberLogs(startDate, endDate, memberSelected, serverSeleted);
        if (response.status === 200) {
            setLogs(response.data.data);
        } else {
            Notification('เกิดข้อผิดพลาดบางอย่าง กรุณาลองอีกครั้ง', 'error');
            setLogs(null);
        }
        setShowResult(true);
        setLoading(false);
    }

    const onServerChange = (e) => {
        setServerSelected(e);
    }

    const previewImage = (log) => {
        const image = log.image;
        setImageSelected(image);
        setShowImage(true);
    }

    const onShowResult = () => {
        const members = member ? member : null;
        const name = members && memberSelected ? members[members.findIndex(v => v.id === memberSelected)].nickname : '';

        let total = 0;
        let foundAndSure = [];
        let foundButMany = [];
        let notsure = [];
        let fake = [];
        let undetection = [];
        const columns = [
            {
                title: <b>ธนาคาร</b>,
                dataIndex: 'bank',
                key: 'bank',
                width: 100,
            },
            {
                title: <b>จำนวนเงิน (บาท)</b>,
                dataIndex: 'total',
                key: 'total',
                width: 100,
                align: 'center'
            },
        ];

        let results = [
            {
                bank: "ธนาคารกสิกร",
                total: 0
            },
            {
                bank: "ธนาคารกรุงไทย",
                total: 0
            },
            {
                bank: "ธนาคารกรุงศรี",
                total: 0
            },
            {
                bank: "ธนาคารไทยพานิชย์",
                total: 0
            },
            {
                bank: "ธนาคารกรุงเทพ",
                total: 0
            },
            {
                bank: "ธนาคารออมสิน",
                total: 0
            },
            {
                bank: "ธนาคารทหารไทยธนชาต",
                total: 0
            },
            {
                bank: "เป๋าตังค์",
                total: 0
            },
            {
                bank: "ธนาคาร ธ.ก.ส.",
                total: 0
            },
            {
                bank: "ไม่สามารถตรวจจับธนาคารได้",
                total: 0
            }
        ];

        if (logs) {
            logs.map(v => {
                const index = results.findIndex(rs => rs.bank === v.bank);
                results[index].total += v.amount;
                total += v.amount;
                return v;
            });

            foundAndSure = logs.filter(v => v.detectionType === 'found and sure');
            foundButMany = logs.filter(v => v.detectionType === 'found but many');
            notsure = logs.filter(v => v.detectionType === 'not sure');
            fake = logs.filter(v => v.detectionType === 'fake');
            undetection = logs.filter(v => v.detectionType === 'undetection');

            results.sort((a, b) => {
                return b.total - a.total;
            });
        }

        if (!logs) return <Empty description={<b>ไม่มีข้อมูล</b>} />

        if (showResult) {
            return <div>
                <Descriptions size="small" bordered>
                    <Descriptions.Item label={<b>ประเภท</b>}>{reportTypes[reportTypes.findIndex(v => v.value === reportTypeSelected)].text}</Descriptions.Item>
                    <Descriptions.Item label={<b>ชื่อเล่น</b>}>{name}</Descriptions.Item>
                    <Descriptions.Item label={<b>ยอดรวม</b>}><Tag color="#87d068">{numberWithCommas(total) + " บาท"}</Tag></Descriptions.Item>
                    <Descriptions.Item label={<b>รายการเช็คสลิป</b>}><Tag color="#87d068">{logs.length + " รายการ"}</Tag></Descriptions.Item>
                    <Descriptions.Item label={<b>สถานะสลิปจริง</b>}><Tag color="green">{foundAndSure.length + " รายการ"}</Tag></Descriptions.Item>
                    <Descriptions.Item label={<b>สถานะสลิปจริงมีมากกว่า 1</b>}>{foundButMany.length + " รายการ"}</Descriptions.Item>
                    <Descriptions.Item label={<b>สถานะไม่แน่ใจ</b>}>{notsure.length + " รายการ"}</Descriptions.Item>
                    <Descriptions.Item label={<b>สถานะอาจเป็นสลิปปลอม</b>}><Tag color="red">{fake.length + " รายการ"}</Tag></Descriptions.Item>
                    <Descriptions.Item label={<b>สถานะไม่สามารถตรวจจับได้</b>}><Tag color="orange">{undetection.length + " รายการ"}</Tag></Descriptions.Item>
                </Descriptions>
                <Table style={{ marginTop: 5 }} columns={columns} dataSource={[...results]} pagination={{ pageSize: 5 }} size={"small"} />
            </div>
        }

        return <></>;
    }

    return <>
        <Spin spinning={loading}>
            <Title level={4}>รายงานรายบุคคล</Title>
            <Space>
                {servers ?
                    <Select mode="multiple" style={{ width: '200px' }} placeholder="เลือกบัญชี" onChange={(e) => onServerChange(e)}>
                        {servers.map(v => <Select.Option value={v.id}>{v.name}</Select.Option>)}
                    </Select> : <></>}
                <Select style={{ width: 150 }} placeholder="เลือกรายงาน" onChange={(e) => onReportTypeChange(e)}>
                    {reportTypes.map(v => <Select.Option value={v.value}>{v.text}</Select.Option>)}
                </Select>
                {reportTypeSelected === 'between' ? <RangePicker placeholder={['ตั้งแต่วันที่', "ถึงวันที่"]} onChange={(e) => onChangeDate(e)} /> : <></>}
                {member ?
                    <Select style={{ width: 150 }} placeholder="เลือกสมาชิก" onChange={(e) => onMemberChange(e)}>
                        {member.map(v => <Select.Option value={v.id}>{v.nickname}</Select.Option>)}
                    </Select> : <></>}
                <Button type="primary" onClick={() => onSearch()}>ค้นหา</Button>
            </Space>
            <Tabs defaultActiveKey="1" style={{ marginTop: 10 }}>
                <TabPane
                    tab={
                        <span>
                            <IdcardOutlined />
                            ข้อมูลโดยรวม
                        </span>
                    }
                    key="1"
                >
                    {onShowResult()}
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            <IdcardOutlined />
                            รายการ
                        </span>
                    }
                    key="2"
                >
                    <Table locale={{ emptyText: <Empty description={<b>ไม่พบข้อมูล</b>} /> }} size="small" columns={logColumns(previewImage)} dataSource={logs ? [...logs] : []} scroll={{ x: 1200 }} pagination={{ defaultPageSize: 50, position: ['bottomRight'] }} />
                    {imageSelected ?
                        <Image
                            width={200}
                            style={{ display: 'none' }}
                            src={imageSelected}
                            preview={{
                                visible: showImage,
                                src: imageSelected,
                                onVisibleChange: value => {
                                    setImageSelected(null);
                                    setShowImage(value);
                                },
                            }}
                        /> : <></>}
                </TabPane>
            </Tabs>

        </Spin>
    </>
}

export default MinorReport;