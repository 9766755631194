import React, { Component } from 'react';
import { AreaChartOutlined, AuditOutlined, CheckOutlined, ExportOutlined, FileImageFilled, UserOutlined } from "@ant-design/icons";
import CheckSlip from "./pages/CheckSlip/CheckSlip";
import Member from "./pages/Member/Member";
import SlipImage from "./pages/SlipImage/SlipImage";
import MinorReport from './pages/MinorReport/MinorReport';
import OverviewReport from './pages/OverviewReport/OverviewReport';
import Deposit from './pages/Deposit/Deposit';
import Deposit2Import from './pages/Deposit2Import/Deposit2Import';

const routes = [
  {
    path: '/',
    exact: true,
    name: 'checkSlip',
    nameTH: 'เช็คสลิป',
    role: ['admin', 'member', 'manager', 'moderator'],
    icon: <CheckOutlined />,
    component: CheckSlip,
  },
  {
    path: '/deposit',
    exact: true,
    name: 'deposit',
    nameTH: 'หน้าเงินฝาก',
    role: ['admin', 'manager', 'moderator'],
    icon: <ExportOutlined />,
    component: Deposit,
  },
  // {
  //   path: '/deposit2-import',
  //   exact: true,
  //   name: 'deposit',
  //   nameTH: 'หน้าเงินฝากเฉพาะ',
  //   role: ['admin', 'manager', 'moderator'],
  //   icon: <ExportOutlined />,
  //   component: Deposit2Import,
  // },
  {
    path: '/member',
    exact: true,
    name: 'member',
    nameTH: 'จัดการสมาชิก',
    role: ['admin', 'moderator'],
    icon: <UserOutlined />,
    component: Member,
  },
  {
    path: '/image',
    exact: true,
    name: 'image',
    nameTH: 'คลังสลิป',
    role: ['admin', 'member', 'manager', 'moderator'],
    icon: <FileImageFilled />,
    component: SlipImage,
  },
  {
    path: '/overview-report',
    exact: true,
    name: 'OverviewReport',
    nameTH: 'รายงานภาพรวม',
    role: ['admin', 'moderator'],
    icon: <AreaChartOutlined />,
    component: OverviewReport,
  },
  {
    path: '/minor-report',
    exact: true,
    name: 'MinorReport',
    nameTH: 'รายงานรายบุคคล',
    role: ['admin', 'moderator'],
    icon: <AuditOutlined />,
    component: MinorReport,
  },
]

export default routes;