import { connect } from 'socket.io-client'
let socket;
let socketSittiphon;
let socketSuchada;

export const initiateSocketConnection = (endpoint) => {
    socket = connect(endpoint);
    return socket;
}

export const disconnectSocket = () => {
    if (socket) socket.disconnect();
}

export const initiateSocketOnlyImportConnection = (endpointSittiphon, endpointSuchada) => {
    socketSittiphon = connect(endpointSittiphon + ":5000/");
    socketSuchada = connect(endpointSuchada + ":5000/");
    return {
        socketSittiphon,
        socketSuchada
    }
}

export const disconnectSocketOnlyImport = () => {
    if (socketSittiphon) socketSittiphon.disconnect();
    if (socketSuchada) socketSuchada.disconnect();
}

export const initiateSocketSittiphon = (endpoint) => {
    socketSittiphon = connect(endpoint + ":5000/");
    return socketSittiphon;
}

export const initiateSocketSuchada = (endpoint) => {
    socketSuchada = connect(endpoint + ":5000/");
    return socketSuchada;
}

export const disconnectSocketSittiphon = () => {
    if (socketSittiphon) socketSittiphon.disconnect();
}

export const disconnectSocketSuchada = () => {
    if (socketSuchada) socketSuchada.disconnect();
}