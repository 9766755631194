import React, { useEffect, useState } from "react";
import { Bar, Column } from '@ant-design/charts';
import './overviewReport.css'
import { Button, DatePicker, Divider, Empty, Result, Select, Space, Spin, Tag, Typography } from "antd";
import moment from "moment";
import Notification from "../../components/Notification";
import { DollarCircleOutlined } from "@ant-design/icons";
import { numberWithCommas } from "../../utils/utils";
import { getMembers, getSearchMemberLogsFieldSpecify, getServerOnProfile, getServers } from "../../service/services";
import { useHistory } from "react-router-dom";
import { disconnectSocket } from "../../service/socketio.service";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const reportTypes = [
    {
        value: 'now',
        text: 'วันนี้'
    },
    {
        value: 'yesterday',
        text: 'เมื่อวานนี้'
    },
    {
        value: 'weekly',
        text: 'อาทิตย์นี้'
    },
    {
        value: 'monthly',
        text: 'เดือนนี้'
    },
    {
        value: 'between',
        text: 'เลือกวันที่'
    },
];

const OverviewReport = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [reportTypeSelected, setReportTypeSelected] = useState(null);
    const [servers, setServers] = useState([]);
    const [serverSeleted, setServerSelected] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [logs, setLogs] = useState(null);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD 00:00:00"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD 23:59:59"));
    const [members, setMembers] = useState(null);

    useEffect(() => {
        disconnectSocket();
        const response = getMembers();
        response.then(res => {
            if (res.status !== 200) {
                setMembers(null);
            } else {
                setMembers(res.data.data);
            }
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        if (servers.length === 0) {
            try {
                if (!JSON.parse(localStorage.getItem('profile')).servers) {
                    getServers().then(res => {
                        if (res.status === 200) setServers(res.data.data);
                        else setServers([]);
                    }).catch(reason => setServers([]));
                } else {
                    const servers = getServerOnProfile();
                    setServers(servers);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                history.push('/signin');
            }
        }
    }, [])

    const onChangeDate = (e) => {
        if (e) {
            const startDate = moment(e[0]).format("YYYY-MM-DD 00:00:00");
            const endDate = moment(e[1]).format("YYYY-MM-DD 23:59:59");
            setStartDate(startDate);
            setEndDate(endDate);
        }
    }

    const onReportTypeChange = (e) => {
        let startNewDate = '';
        let endNewDate = '';
        if (e === 'now') {
            startNewDate = moment().format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().format("YYYY-MM-DD 23:59:59");
        } else if (e === 'yesterday') {
            startNewDate = moment().subtract(1, 'day').format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().subtract(1, 'day').format("YYYY-MM-DD 23:59:59");
        } else if (e === 'weekly') {
            startNewDate = moment().subtract(6, 'day').format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().format("YYYY-MM-DD 23:59:59");
        } else if (e === 'monthly') {
            startNewDate = moment().subtract(30, 'day').format("YYYY-MM-DD 00:00:00");
            endNewDate = moment().format("YYYY-MM-DD 23:59:59");
        }
        setShowResult(false);
        setStartDate(startNewDate);
        setEndDate(endNewDate);
        setReportTypeSelected(e);
    }

    const onSearch = async () => {

        if (serverSeleted.length === 0) {
            Notification('กรุณาเลือกบัญชี', 'error');
            return;
        }

        if (!reportTypeSelected) {
            Notification('กรุณาเลือกประเภทรายงาน', 'error');
            return;
        }

        if (!startDate || !endDate) {
            Notification('กรุณาเลือกวันที่', 'error');
            return;
        }

        setLoading(true);
        const response = await getSearchMemberLogsFieldSpecify(startDate, endDate, serverSeleted);
        if (response.status === 200) {
            setLogs(response.data.data);
        } else {
            Notification('เกิดข้อผิดพลาดบางอย่าง กรุณาลองอีกครั้ง', 'error');
            setLogs(null);
        }
        setShowResult(true);
        setLoading(false);
    }

    const onReset = () => {
        setLogs(null);
        setServerSelected([]);
        setReportTypeSelected(null);
    }

    const onServerChange = (e) => {
        setServerSelected(e);
    }

    const chartByTotal = () => {
        if (!logs) return <><Title style={{ marginTop: 10 }} level={4}>ยอดรวมทั้งหมด</Title><Empty description={<b>ไม่มีข้อมูล</b>}><Divider style={{ margin: '10px 0' }} /></Empty></>;

        let total = 0;
        logs.forEach(v => {
            total += v.amount;
        });

        return <>
            <Result
                style={{ padding: '8px 8px' }}
                icon={<DollarCircleOutlined color="#d48806" />}
                title={<>ยอดรวมทั้งหมด {<b style={{ color: '#87d068' }}>{numberWithCommas(total)}</b>} บาท</>}
            />
            <Divider style={{ margin: '10px 0' }} />
        </>
    }

    const chartByBank = () => {
        if (!logs) return <><Title style={{ marginTop: 10 }} level={4}>ยอดรวมแยกตามธนาคาร</Title><Empty description={<b>ไม่มีข้อมูล</b>}></Empty><Divider style={{ margin: '10px 0' }} /></>;

        let results = [
            {
                bank: "ธนาคารกสิกร",
                total: 0
            },
            {
                bank: "ธนาคารกรุงไทย",
                total: 0
            },
            {
                bank: "ธนาคารกรุงศรี",
                total: 0
            },
            {
                bank: "ธนาคารไทยพานิชย์",
                total: 0
            },
            {
                bank: "ธนาคารกรุงเทพ",
                total: 0
            },
            {
                bank: "ธนาคารออมสิน",
                total: 0
            },
            {
                bank: "ธนาคารทหารไทยธนชาต",
                total: 0
            },
            {
                bank: "เป๋าตังค์",
                total: 0
            },
            {
                bank: "ธนาคาร ธ.ก.ส.",
                total: 0
            },
            {
                bank: "ไม่สามารถตรวจจับธนาคารได้",
                total: 0
            }
        ];

        logs.map(v => {
            const index = results.findIndex(rs => rs.bank === v.bank);
            results[index].total += v.amount;
            return v;
        });

        results.sort((a, b) => {
            return b.total - a.total;
        });

        const config = {
            data: results,
            xField: 'total',
            yField: 'bank',
            seriesField: 'bank',
            legend: {
                position: 'top-left',
            },
        };

        return <>
            <Title style={{ marginTop: 10 }} level={4}>ยอดรวมแยกตามธนาคาร</Title>
            <Bar {...config} />
            <Divider style={{ margin: '10px 0' }} />
        </>
    }

    const chartByMember = () => {
        if (!logs) return <><Title style={{ marginTop: 10 }} level={4}>ยอดรวมแยกตามสมาชิก</Title><Empty description={<b>ไม่มีข้อมูล</b>}><Divider style={{ margin: '10px 0' }} /></Empty></>;

        const results = [];
        members.forEach(member => {
            const memberLogs = logs.filter(log => log.member.id === member.id);
            let total = 0;
            memberLogs.forEach(v => {
                total += v.amount;
            });

            const data = {
                nickname: member.nickname,
                total
            };
            results.push(data);
        });

        const config = {
            data: results,
            xField: 'nickname',
            yField: 'total',
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
        };

        return <>
            <Title style={{ marginTop: 10 }} level={4}>ยอดรวมแยกตามสมาชิก</Title>
            <Column {...config} />
            <Divider style={{ margin: '10px 0' }} />
        </>;
    }

    return (
        <>
            <Spin spinning={loading}>
                <Title level={4}>รายงานภาพรวม</Title>
                <Space>
                    {servers ?
                        <Select mode="multiple" style={{ width: '200px' }} placeholder="เลือกบัญชี" onChange={(e) => onServerChange(e)}>
                            {servers.map(v => <Select.Option value={v.id}>{v.name}</Select.Option>)}
                        </Select> : <></>}
                    <Select style={{ width: 150 }} placeholder="เลือกรายงาน" onChange={(e) => onReportTypeChange(e)}>
                        {reportTypes.map(v => <Select.Option value={v.value}>{v.text}</Select.Option>)}
                    </Select>
                    {reportTypeSelected === 'between' ? <RangePicker placeholder={['ตั้งแต่วันที่', "ถึงวันที่"]} onChange={(e) => onChangeDate(e)} /> : <></>}
                    <Button type="primary" onClick={() => onSearch()}>ดูภาพรวม</Button>
                    <Button type="default" danger onClick={() => onReset()}>Refresh</Button>
                </Space>
            </Spin>
            {chartByTotal()}
            {chartByMember()}
            {chartByBank()}
        </>
    )
}

export default OverviewReport;