import React, { useEffect, useState } from "react";
import { Buffer } from 'buffer';
import { Button, Col, Descriptions, Divider, Empty, Modal, Result, Row, Space, Spin, Table, Tag, Typography } from "antd";
import { useHistory } from "react-router-dom";
import './deposit-2-import.css';
import { getDeposit, getDepositYesterday, getServerOnProfile, getServers } from "../../service/services";
import { containArray } from "../../utils/utils";
import { disconnectSocketSittiphon, disconnectSocketSuchada, initiateSocketSuchada } from "../../service/socketio.service";

const { Title } = Typography;
const config = [{ name: "Suchada", ip: "http://68.183.191.144" }, { name: "Sittiphon", ip: "http://134.209.104.110" }];
const configNameOnly = ["Suchada", "Sittiphon"];

const renderColumns = () => {
    const columns = [
        {
            title: <b>วันที่</b>,
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: <b>เวลา</b>,
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: <b>โอนจาก</b>,
            dataIndex: 'account_from',
            key: 'account_from'
        },
        {
            title: <b>จำนวนเงิน</b>,
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: <b>เช็ค</b>,
            dataIndex: 'check',
            key: 'check',
            render: (text, record, index) => {
                if (text) return 'เช็คแล้ว';
                return 'ยังไม่เช็ค';
            }
        },
    ];

    return columns;
}

const rowSetup = (record, index) => {
    if (record.check) {
        return 'checked-match';
    }
    return '';
}

const Deposit2Import = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [smsSittiphon, setSmsSittiphon] = useState(null);
    const [totalSmsSittiphon, setTotalSmsSittiphon] = useState(null);
    const [smsSuchada, setSmsSuchada] = useState(null);
    const [totalSmsSuchada, setTotalSmsSuchada] = useState(null);
    const [typeOfDeposit, setTypeOfDeposit] = useState('today');
    const [bank] = useState('KBank');
    const [canAccess, setCanAccess] = useState(false);
    const [servers, setServers] = useState([]);
    const [profile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [role] = useState(profile ? Buffer.from(profile.role.substring(1, profile.role.length - 2), 'base64').toString('utf8') : "");

    useEffect(() => {
        if (servers.length === 0) {
            try {
                if (!JSON.parse(localStorage.getItem('profile')).servers) {
                    getServers().then(res => {
                        if (res.status === 200) {
                            const serversNameOnly = res.data.data.map(v => v.name);
                            const isCan = containArray(configNameOnly, serversNameOnly);
                            setCanAccess(isCan);
                            setServers(res.data.data);
                        } else setServers([]);
                    }).catch(reason => setServers([]));
                } else {
                    const servers = getServerOnProfile();
                    const serversNameOnly = servers.map(v => v.name);
                    const isCan = containArray(configNameOnly, serversNameOnly);
                    setCanAccess(isCan);
                    setServers(servers);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                history.push('/signin');
            }
        }
        connectServerSuchada();
        connectServerSittiphon();

        return () => {
            disconnectSocketSittiphon();
            disconnectSocketSuchada();
        }

    }, []);

    const connectServerSuchada = async () => {
        setLoading(true);
        try {
            disconnectSocketSittiphon();
            disconnectSocketSuchada();
            const socketSuchada = initiateSocketSuchada(config[0].ip);
            setTypeOfDeposit('now');

            socketSuchada.on('smsSent', async () => {
                const url = config[0].ip;
                const response = await getDeposit(url, bank);
                if (response.status === 200) {
                    setTotalSmsSuchada(response.data.totalSms);
                    setSmsSuchada(response.data.sms);
                } else {
                    setTotalSmsSuchada(null);
                    setSmsSuchada(null);
                }
            });

            socketSuchada.on('smsChecked', async (smsChecked) => {
                const index = smsSuchada ? smsSuchada.findIndex(v => v._id.toString() === smsChecked._id.toString()) : -1;

                if (index !== -1) {
                    if (smsSuchada[index].check !== smsChecked.check) {
                        const url = config[0].ip + ":5000/";
                        const response = await getDeposit(url, bank);
                        if (response.status === 200) {
                            setTotalSmsSuchada(response.data.totalSms);
                            setSmsSuchada(response.data.sms);
                        } else {
                            setTotalSmsSuchada(null);
                            setSmsSuchada(null);
                        }
                    }
                }

            });

            // Suchada
            const url = config[0].ip + ":5000/";
            const response = await getDeposit(url, bank);
            if (response.status === 200) {
                setTotalSmsSuchada(response.data.totalSms);
                setSmsSuchada(response.data.sms);
            } else {
                setTotalSmsSuchada(null);
                setSmsSuchada(null);
            }

            setLoading(false);
        } catch (error) {
            setTotalSmsSuchada(null);
            setSmsSuchada(null);
            setLoading(false);
        }
    }

    const connectServerSittiphon = async () => {
        setLoading(true);
        try {
            const socketSittiphon = initiateSocketSuchada(config[1].ip);
            socketSittiphon.on('smsSent', async () => {
                const url = config[1].ip + ":5000/";;
                const response = await getDeposit(url, bank);
                if (response.status === 200) {
                    setTotalSmsSittiphon(response.data.totalSms);
                    setSmsSittiphon(response.data.sms);
                } else {
                    setTotalSmsSittiphon(null);
                    setSmsSittiphon(null);
                }
            });

            socketSittiphon.on('smsChecked', async (smsChecked) => {
                const index = smsSittiphon ? smsSittiphon.findIndex(v => v._id.toString() === smsChecked._id.toString()) : -1;

                if (index !== -1) {
                    if (smsSittiphon[index].check !== smsChecked.check) {
                        const url = config[1].ip + ":5000/";
                        const response = await getDeposit(url, bank);
                        if (response.status === 200) {
                            setTotalSmsSittiphon(response.data.totalSms);
                            setSmsSittiphon(response.data.sms);
                        } else {
                            setTotalSmsSittiphon(null);
                            setSmsSittiphon(null);
                        }
                    }
                }
            });

            // Sittiphon
            const url = config[1].ip + ":5000/";
            const response = await getDeposit(url, bank);
            if (response.status === 200) {
                setTotalSmsSittiphon(response.data.totalSms);
                setSmsSittiphon(response.data.sms);
            } else {
                setTotalSmsSittiphon(null);
                setSmsSittiphon(null);
            }

            setLoading(false);
        } catch (error) {
            setTotalSmsSittiphon(null);
            setSmsSittiphon(null);
            setLoading(false);
        }
    }

    const depositYesterdaySittiphon = async () => {
        disconnectSocketSittiphon();
        setLoading(true);
        const url = config[1].ip + ":5000/";
        try {
            const response = await getDepositYesterday(url, bank);
            if (response.status === 200) {
                setTotalSmsSittiphon(response.data.totalSms);
                setSmsSittiphon(response.data.sms);
            } else {
                setTotalSmsSittiphon(null);
                setSmsSittiphon(null);
            }
            setTypeOfDeposit('yesterday');
        } catch (error) {
            setTotalSmsSittiphon(null);
            setSmsSittiphon(null);
        }

        setLoading(false);
    }

    const depositYesterdaySuchada = async () => {
        disconnectSocketSuchada();
        setLoading(true);
        const url = config[0].ip + ":5000/";
        try {
            const response = await getDepositYesterday(url, bank);
            if (response.status === 200) {
                setTotalSmsSuchada(response.data.totalSms);
                setSmsSuchada(response.data.sms);
            } else {
                setTotalSmsSuchada(null);
                setSmsSuchada(null);
            }
            setTypeOfDeposit('yesterday');
        } catch (error) {
            setTotalSmsSuchada(null);
            setSmsSuchada(null);
        }

        setLoading(false);
    }

    const showData = () => {
        return (
            <>
                <Title level={4}>หน้าเงินฝาก</Title>
                <Row gutter={16} style={{ textAlign: 'center' }}>
                    <Col span={12}>
                        <Title level={5}><Tag color={'#00a950'} style={{ fontSize: 16 }}>Sittiphon</Tag></Title>
                        <Space>
                            <Button type="primary" size="small" onClick={() => connectServerSittiphon()}>ดูยอดวันนี้</Button>
                            <Button type="primary" size="small" onClick={() => depositYesterdaySittiphon()}>ดูยอดเมื่อวาน</Button>
                        </Space>
                        <Divider style={{ margin: '5px' }} />
                        {role !== 'member' ?
                            <Descriptions column={2}>
                                <Descriptions.Item style={{ paddingBottom: 4 }} label={<b>ยอดเงิน</b>}>{totalSmsSittiphon ? totalSmsSittiphon.totalTransaction.split(" ")[1] : 0}</Descriptions.Item>
                                <Descriptions.Item style={{ paddingBottom: 4 }} label={<b>รายการ</b>}>{totalSmsSittiphon ? totalSmsSittiphon.transaction.split(" ")[1] : 0}</Descriptions.Item>
                            </Descriptions> : <></>}
                        <Table locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} size="small" columns={renderColumns()} rowClassName={rowSetup} dataSource={smsSittiphon} rowKey="_id" pagination={{ defaultPageSize: 20, position: ['bottomRight'] }} />;
                    </Col>
                    <Col span={12}>
                        <Title level={5}><Tag color={'#00a950'} style={{ fontSize: 16 }}>Suchada</Tag></Title>
                        <Space>
                            <Button type="primary" size="small" onClick={() => connectServerSuchada()}>ดูยอดวันนี้</Button>
                            <Button type="primary" size="small" onClick={() => depositYesterdaySuchada()}>ดูยอดเมื่อวาน</Button>
                        </Space>
                        <Divider style={{ margin: '5px' }} />
                        {role !== 'member' ?
                            <Descriptions column={2}>
                                <Descriptions.Item style={{ paddingBottom: 4 }} label={<b>ยอดเงิน</b>}>{totalSmsSuchada ? totalSmsSuchada.totalTransaction.split(" ")[1] : 0}</Descriptions.Item>
                                <Descriptions.Item style={{ paddingBottom: 4 }} label={<b>รายการ</b>}>{totalSmsSuchada ? totalSmsSuchada.transaction.split(" ")[1] : 0}</Descriptions.Item>
                            </Descriptions> : <></>}
                        <Table locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} size="small" columns={renderColumns()} rowClassName={rowSetup} dataSource={smsSuchada} rowKey="_id" pagination={{ defaultPageSize: 20, position: ['bottomRight'] }} />;
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <>
            <Spin spinning={loading}>
                {canAccess ?
                    showData() :
                    <Result
                        status="warning"
                        title="ไม่มีสิทธิ์เข้าใช้งาน"
                        // extra={
                        //     <Button type="primary" key="console" onClick={() => history.push('/deposit')}>
                        //         กลับหน้าเงินฝาก
                        //     </Button>
                        // }
                    />
                }
            </Spin>
        </>
    )
}

export default Deposit2Import;