import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Descriptions, Divider, Empty, Input, Select, Space, Spin, Table, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDeposit, getDepositBetween, getDepositYesterday, getServerOnProfile, getServers } from '../../service/services';
import { disconnectSocket, initiateSocketConnection } from '../../service/socketio.service';
import Highlighter from 'react-highlight-words';
import './deposit.css';
import { Buffer } from 'buffer';
import { numberWithCommas } from '../../utils/utils';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Title } = Typography;

const renderColumns = (getColumnSearchProps) => {
    const columns = [
        {
            title: <b>UID</b>,
            dataIndex: '_id',
            key: '_id',
            width: '30%',
            ...getColumnSearchProps('_id'),
        },
        {
            title: <b>วันที่</b>,
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            ...getColumnSearchProps('date'),
        },
        {
            title: <b>เวลา</b>,
            dataIndex: 'time',
            key: 'time',
            ...getColumnSearchProps('time')
        },
        {
            title: <b>โอนจาก</b>,
            dataIndex: 'account_from',
            key: 'account_from',
            ...getColumnSearchProps('account_from')
        },
        {
            title: <b>จำนวนเงิน</b>,
            dataIndex: 'amount',
            key: 'amount',
            ...getColumnSearchProps('amount')
        },
        {
            title: <b>เช็ค</b>,
            dataIndex: 'check',
            key: 'check',
            ...getColumnSearchProps('check')
        },
    ];

    return columns;
}


const Deposit = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [servers, setServers] = useState([]);
    const [serverSeleted, setServerSelected] = useState(null);
    const [defaultServer, setDefaultServer] = useState(null);
    const [sms, setSms] = useState([]);
    const [totalSms, setTotalSms] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [typeOfDeposit, setTypeOfDeposit] = useState('today');
    const [searchedColumn, setSearchedColumn] = useState(-1);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')));
    const [role, setRole] = useState(profile ? Buffer.from(profile.role.substring(1, profile.role.length - 2), 'base64').toString('utf8') : "");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    let searchInput = useRef(null);

    useEffect(() => {
        disconnectSocket();
        if (servers.length === 0) {
            try {
                if (!JSON.parse(localStorage.getItem('profile')).servers) {
                    getServers().then(res => {
                        if (res.status === 200) setServers(res.data.data);
                        else setServers([]);
                    }).catch(reason => setServers([]));
                } else {
                    const servers = getServerOnProfile();
                    setServers(servers);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                history.push('/signin');
            }
        }

        if (sms) {
            if (sms.length !== 0) newSmsRelease();
        }

        return () => {
            disconnectSocket();
        }
    }, [sms]);

    const newSmsRelease = () => {
        disconnectSocket();
        const url = serverSeleted + "/";
        const socket = initiateSocketConnection(url);
        setTypeOfDeposit('now');
        socket.on('smsSent', (newSms) => {
            const dataTotalTransactionSplit = totalSms.totalTransaction.replace(/,/g, "").split(" ");
            let sumTotalTransaction = parseFloat(dataTotalTransactionSplit[1]);
            let count = 0;
            const filterSms = [];
            newSms.forEach(v => {
                if (v.type === 'เงินฝาก' && v.sms_address === defaultServer.bank) {
                    filterSms.push(v);
                    sumTotalTransaction += v.amount;
                    count++;
                }
            })
            sumTotalTransaction = numberWithCommas(sumTotalTransaction);

            const dataTransactionSplit = totalSms.transaction.replace(",", "").split(" ");
            let sumTransaction = parseFloat(dataTransactionSplit[1]) + count;
            sumTransaction = numberWithCommas(sumTransaction);

            const newTotalSms = {
                totalTransaction: dataTotalTransactionSplit[0] + " " + sumTotalTransaction + " " + dataTotalTransactionSplit[2],
                transaction: dataTransactionSplit[0] + " " + sumTransaction + " " + dataTransactionSplit[2]
            }

            setTotalSms(newTotalSms);
            setSms(filterSms.concat(sms));
        });

        socket.on('smsChecked', async (smsChecked) => {
            const index = sms.findIndex(v => v._id.toString() === smsChecked._id.toString());
            const newSms = [...sms];
            newSms[index] = smsChecked;
            setSms(newSms);
        });
    }

    const onServerChange = (e) => {
        setTotalSms(null);
        setSms(null);
        const myServerIndex = servers.findIndex(server => server.id === e);
        setDefaultServer(servers[myServerIndex]);
        setServerSelected(servers[myServerIndex].ip);
    }

    const connectServer = async () => {
        setLoading(true);
        try {
            const url = serverSeleted + "/";
            const response = await getDeposit(url, defaultServer.bank);
            if (response.status === 200) {
                setTotalSms(response.data.totalSms);
                setSms(response.data.sms);
            } else {
                setTotalSms(null);
                setSms(null);
            }

            setLoading(false);
        } catch (error) {
            setTotalSms(null);
            setSms(null);
            setLoading(false);
        }
    }

    const depositYesterday = async () => {
        disconnectSocket();
        setLoading(true);
        const url = serverSeleted + "/";
        try {
            const response = await getDepositYesterday(url, defaultServer.bank);
            if (response.status === 200) {
                setTotalSms(response.data.totalSms);
                setSms(response.data.sms);
            } else {
                setTotalSms(null);
                setSms(null);
            }
            setTypeOfDeposit('yesterday');
        } catch (error) {
            setTotalSms(null);
            setSms(null);
        }

        setLoading(false);
    }

    const deposit7days = async () => {
        disconnectSocket();
        setLoading(true);
        const url = serverSeleted + "/";
        const startDate = moment().add(-7, 'day').format("YYYY-MM-DD 00:00:00");
        const endDate = moment().format("YYYY-MM-DD 23:59:59");
        try {
            const response = await getDepositBetween(url, defaultServer.bank, startDate, endDate);
            if (response.status === 200) {
                setTotalSms(response.data.totalSms);
                setSms(response.data.sms);
            } else {
                setTotalSms(null);
                setSms(null);
            }
        } catch (error) {
            setTotalSms(null);
            setSms(null);
        }

        setLoading(false);
        setTypeOfDeposit('weekly');
    }

    const depositByRange = async () => {
        disconnectSocket();
        setLoading(true);
        const url = serverSeleted + "/";
        try {
            const response = await getDepositBetween(url, defaultServer.bank, startDate, endDate);
            if (response.status === 200) {
                setTotalSms(response.data.totalSms);
                setSms(response.data.sms);
            } else {
                setTotalSms(null);
                setSms(null);
            }
        } catch (error) {
            setTotalSms(null);
            setSms(null);
        }

        setLoading(false);
        setTypeOfDeposit('range');
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`ค้นหาด้วย ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        ค้นหา
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        เคลียร์
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        รีเซ็ต
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text, record, index) => {

            if (dataIndex === 'check') {
                if (text) return record.checker_name === 'MySMS' ? record.checker_name + ' เช็ค' : record.checker_name + 'เช็ค';
                else text = 'ยังไม่เช็ค';
            }

            return searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
        }

    });

    const onChangeDate = (e) => {
        if (e) {
            const startDate = moment(e[0]).format("YYYY-MM-DD 00:00:00");
            const endDate = moment(e[1]).format("YYYY-MM-DD 23:59:59");
            setStartDate(startDate);
            setEndDate(endDate);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    }

    const rowSetup = (record, index) => {
        if (record.check) {
            return 'checked-match';
        }
        return '';
    }

    return <>
        <Spin spinning={loading}>
            <Title level={4}>หน้าเงินฝาก</Title>
            <Space>
                {servers ?
                    <Select style={{ width: '220px' }} placeholder="เลือกเครื่อง" onChange={(e) => onServerChange(e)}>
                        {servers.map(v => <Select.Option value={v.id}>{v.name}</Select.Option>)}
                    </Select> : <></>}
                <Button type="primary" onClick={() => connectServer()}>ดูยอดวันนี้</Button>
                {serverSeleted ? <>
                    <Button type="primary" onClick={() => depositYesterday()}>ดูยอดเมื่อวาน</Button>
                    <Button type="primary" onClick={() => deposit7days()}>ดูยอด 7 วันที่ผ่านมา</Button>
                    <RangePicker placeholder={['ตั้งแต่วันที่', "ถึงวันที่"]} onChange={(e) => onChangeDate(e)} />
                    <Button type="primary" disabled={!(startDate && endDate)} onClick={() => depositByRange()}>ดูยอดตามช่วง</Button>
                </> : <></>}
            </Space>
            <Divider style={{ margin: '10px 0' }} />
            {(role === 'admin' && totalSms) ? <Descriptions>
                <Descriptions.Item label={<b>ประเภท</b>}>{typeOfDeposit === 'now' ? 'วันนี้' : typeOfDeposit === 'yesterday' ? 'เมื่อวานนี้' : typeOfDeposit === 'weekly' ? '7 วันที่ผ่านมา' : moment(startDate).format("ตั้งแต่ DD-MM-YYYY") + " "+ moment(endDate).format("ถึง DD-MM-YYYY")}</Descriptions.Item>
                <Descriptions.Item label={<b>ยอดเงิน</b>}>{totalSms.totalTransaction.replace("ยอดเงินวันนี้ ", "")}</Descriptions.Item>
                <Descriptions.Item label={<b>รายการ</b>}>{totalSms.transaction.replace("ออเดอร์ ", "")}</Descriptions.Item>
            </Descriptions> : <></>}
            <Table locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} size="small" columns={renderColumns(getColumnSearchProps)} rowClassName={rowSetup} dataSource={sms} rowKey="_id" pagination={{ defaultPageSize: 20, position: ['bottomRight'] }} />;
        </Spin>
    </>
}


export default Deposit;