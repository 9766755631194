import axios from "axios";
import Instance from "./Instance";
import moment from "moment";

export const getServers = async () => {
    const data = await Instance.get('server');
    return data;
}

export const getServerOnProfile = () => {
    const serverList = JSON.parse(localStorage.getItem('profile')).servers;
    return JSON.parse(serverList);
}

export const checkSlip = async (server, base64, address, accountNameEN, accountNameTH, nickname) => {
    const url = server + "/sms/check-slip";
    const account = {
        accountNameEN,
        accountNameTH
    };
    // const url = "http://localhost/sms/check-slip";
    const response = await axios.post(url, { image: base64, address, account, checker: nickname }, { timeout: 1000 * 30, headers: { 'Content-Type': 'application/json' } });

    return response;
}

export const saveImage = (img, server, member, text) => {
    const data = {
        img,
        server,
        member,
        text
    };

    Instance.post('image', data).then(v => {
        return;
    }).catch(reason => {
        return;
    });
}

export const saveMemberLog = (member, text, image, date, time, amount, bank, detectionType, server) => {
    const log = {
        member,
        text,
        image,
        date,
        time,
        amount,
        bank,
        detectionType,
        server,
    };
    Instance.post('member-logging', log).then(v => {
        return;
    }).catch(reason => {
        return;
    });
}

export const checkSessionFetcher = async (token) => {
    const response = await Instance.get("auth/check/" + token);
    return response.data;
}

export const login = async (username, password) => {
    const values = {
        username,
        password
    };

    const response = await Instance.post('auth/login', JSON.stringify(values));
    return response;
}

export const logout = async () => {
    const token = localStorage.getItem('token');

    await Instance.get('/auth/logout/' + token);

    localStorage.removeItem('token');
    localStorage.removeItem('profile');
}

export const getMembers = async () => {
    const response = await Instance.get('member');
    return response;
}

export const getSearchMembers = async (nickname) => {
    const response = await Instance.get(`/member/?${(nickname !== "" && `filter=nickname||$starts||${nickname}`)}`);
    return response;
}

export const saveNewMember = async (member) => {
    const response = await Instance.post('member', JSON.stringify(member));
    return response;
}

export const updateMember = async (id, member) => {
    const response = await Instance.patch('member/' + id, JSON.stringify(member));
    return response;
}

export const deleteMember = async (id) => {
    const response = await Instance.delete('member/' + id);
    return response;
}

export const getImages = async (query) => {
    const data = await Instance.get('image' + query);
    return data;
}

export const deleteImageById = async (id) => {
    const response = await Instance.delete('image/' + id);
    return response;
}

export const deleteAllImages = async (body) => {
    const data = await Instance.post('image/delete-all', body);
    return data;
}

export const getSearchMemberLogs = async (startDate, endDate, member, serversSelected) => {
    let query = "?";
    query += "filter=createdAt||$gte||" + startDate + "&filter=createdAt||$lte||" + endDate;
    query += "&filter=member.id||$eq||" + member;
    query += "&filter=server.id||$in||" + serversSelected.join(",");
    const response = await Instance.get('member-logging' + query);

    return response;
}

export const getSearchMemberLogsFieldSpecify = async (startDate, endDate, serversSelected) => {
    let query = "?";
    query += "fields=id,text,bank,date,time,amount,createdAt,updatedAt,detectionType,member";
    query += "&filter=createdAt||$gte||" + startDate + "&filter=createdAt||$lte||" + endDate;
    query += "&filter=server.id||$in||" + serversSelected.join(",");
    // query += "&filter=member.id||$eq||" + memberSelected;
    const response = await Instance.get('member-logging' + query);

    return response;
}

export const getDeposit = async (url, bank) => {
    const d = new Date();
    let currentDate = null;
    if (bank.toLowerCase() === 'kbank')
        currentDate = moment().format('DD/MM/') + (d.getFullYear() + 543).toString().substring(2, 4);
    else
        currentDate = moment().format('DD/MM/') + (d.getFullYear()).toString().substring(2, 4);

    const query = "sms_address=" + bank + "&type=เงินฝาก&date=" + currentDate;
    const response = await axios.get(url + 'sms/today?' + query);
    return response;
}

export const getDepositYesterday = async (url, bank) => {
    const d = new Date();
    let currentDate = null;
    if (bank.toLowerCase() === 'kbank')
        currentDate = moment().subtract(1, 'day').format('DD/MM/') + (d.getFullYear() + 543).toString().substring(2, 4);
    else
        currentDate = moment().subtract(1, 'day').format('DD/MM/') + (d.getFullYear()).toString().substring(2, 4);

    const query = "sms_address=" + bank + "&type=เงินฝาก&date=" + currentDate;
    const response = await axios.get(url + 'sms/today?' + query);
    return response;
}

export const getDepositBetween = async (url, bank, startDate, endDate) => {
    const query = "sms_address=" + bank + "&type=เงินฝาก&startDate=" + startDate + "&endDate=" + endDate;
    const response = await axios.get(url + 'sms/between?' + query);
    return response;
}