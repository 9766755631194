import moment from "moment";

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const containArray = (checkArray, startArray) => {
    return checkArray.every(element => {
        return startArray.includes(element);
    });
}


export const guessYear = (rawYear) => {
    rawYear = parseInt(rawYear);
    const currentYear = parseInt((new Date().getFullYear() + 543).toString().slice(-2));
    if (currentYear === rawYear) return new Date().getFullYear();

    const diff = currentYear - rawYear;
    const yearDiff = parseInt((new Date().getFullYear()) - diff);
    return yearDiff;
}

export const getDateTimeDuration = (fromDateTime, toDateTime) => {
    const duration = moment.duration(fromDateTime.diff(toDateTime));
    const days = duration.days();
    const hours = duration.hours();
    const minute = duration.minutes();

    const data = {
        days,
        hours,
        minute,
        text: "",
        isWarning: false
    }

    if (days > 0) {
        data.isWarning = true;
        data.text = `${days} วัน ${hours} ชั่วโมง ${minute} นาที`;
    } else {
        if (hours > 0) { 
            data.isWarning = true;
            data.text = `${hours} ชั่วโมง ${minute} นาที`;
        } else data.text = `${minute} นาที`;
    }
    
    return data;
}

// export const numberWithCommas;